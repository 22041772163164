/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


.flex {
  display: flex;
}

.full-width {
  width: 100% !important;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.align-self-center {
  align-self: center;
}

.align-self-end {
  align-self: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-space-around {
  justify-content: space-around;
}

.justify-content-space-evenly {
  justify-content: space-evenly;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-space-end {
  justify-content: flex-end;
}

.align-items-center {
  align-items: center;
}

.flex-column {
  flex-direction: column;
}

.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
}

.text-tiny {
  font-size: 11px !important;
}

.text-small {
  font-size: 12px !important;
}

.text-medium {
  font-size: 14px !important;
}

.text-normal {
  font-size: 16px !important;
}

.text-large {
  font-size: 24px !important;
}

.text-xlarge {
  font-size: 36px !important;
}

.text-xxlarge {
  font-size: 48px !important;
}

.radius {
  border-radius: 8px !important;
}

.divider {
  background: var(--ion-color-light);
  margin: 8px 0;
  height: 1px;
  width: 100%;
}

.bold {
  font-weight: bold !important; 
}

.tab-selected {
	border-bottom: 3px solid var(--color-selected);
}

.vdiv {
	width: 1px; 
	min-height: 100%; 
	background: #d0d0d0; 
	padding-top: 20px; 
	padding-bottom: 20px;	
}

.hdiv {
  min-width: 100%; 
  height: 1px; 
  background: #d0d0d0; 
  padding-left: 20px; 
  padding-right: 20px;  
  
}

.logo {
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  margin-bottom: 100px;
  max-width: 300px;
}

small {
  font-size: 60% !important;
}

.rating {
    left: 28px;
    width: 26px;
    height: 26px;
    margin: 0 auto;
    background: #86d94a;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 15px;
  }

.selected-border {
  border: 2px solid var(--ion-color-primary);
}

ion-button {
  -–border-radius: 15px;
}

.bz-button {
  margin-left: 20%;
  margin-right: 20%;
  margin-top: 20px;
  margin-bottom: 20px;
}